import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { compose, head, prop, omit, over, lensProp, assoc } from 'ramda';
import { toast } from 'react-toastify';

import { Formik, Form } from 'formik';
import { Grid, Checkbox, Typography, TextField, Tooltip, FormControlLabel } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import Modal from '../../../components/Modal';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import Input from '../../../components/Input';
import Loader from '../../../components/Loader';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import Status from '../../../components/Status';

import { formatFilters, formatAvailableEstablishments } from '../../../helpers/formatters';
import { setEmptyEmployee } from '../store/actions';
import { upsertEmployee, loadEmployee, loadRestrictions } from '../store/thunk';
import { validations } from './validations';

import EmployeesDescription from './EmployeesDescription';

import { RESTRICTION_PAGE } from '../../../helpers/restrictions';

import { useStyles } from './styles';

export default function EmployeesRegistration() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();
  const isEmployeeRegister = history.location.pathname == '/novo-usuario';

  const resaleId = process.env.REACT_APP_RESALE_ID || '';

  const { mainUser } = useSelector(state => state.employeesRegistration);
  const { userId, userRestrictions } = useSelector(state => state.profile);
  const { profileId } = useSelector(state => state.profile.submit);
  const { selects } = useSelector(state => state.filters);
  const establishments = useSelector(state => state.login.establishments);

  const {
    employeeId,
    submit,
    isLoading,
    isRestrictionsLoading,
    restrictionsApp,
    restrictionsUser
  } = useSelector(state => state.employeesRegistration);

  const siteRestrictionsType = restrictionsUser?.map(restriction => restriction?.type);
  const siteRestrictionsId = [...new Set(siteRestrictionsType)].sort();

  const appRestrictionsType = restrictionsApp?.map(restriction => restriction?.type);
  const appRestrictionsId = [...new Set(appRestrictionsType)].sort();

  const [selectedUserRestrictionsId, setSelectedUserRestrictionsId] = useState([]);
  const [selectedAppRestrictionsId, setSelectedAppRestrictionsId] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState(0);
  const [isProfileVerificationModalOpen, setIsProfileVerificationModalOpen] = useState(false);
  const [formikEmployeeValues, setFormikEmployeeValues] = useState({});
  const [formikEmployeeActions, setFormikEmployeeActions] = useState({});

  const profilesToDisplay = [2, 3, 5];
  const isUserAdmin = +profileId == 1;

  const allAvailableEstablishments = submit?.availableEstablishments;

  const availableEstablishments = formatAvailableEstablishments([...establishments, ...allAvailableEstablishments])
    ?.filter((establishment, index, self) => index == self.findIndex(establishmentIndex => establishmentIndex?.establishmentId == establishment?.establishmentId));

  const formattedAvailableEstablishments = availableEstablishments.map(establishment => {
    return {
      ...establishment,
      label: isUserAdmin ? `${establishment.establishmentId} - ${establishment.label}` : establishment.label
    }
  });

  useEffect(() => {
    if(history.location.pathname == '/novo-usuario') {
      dispatch(setEmptyEmployee());
      dispatch(loadRestrictions(userId));
    }
  }, [history.location.pathname]);

  useEffect(() => {
    setSelectedUserRestrictionsId(submit?.userRestrictions);
    setSelectedAppRestrictionsId(submit?.appRestrictions);
  }, [submit]);

  useEffect(() => {
    if((history.location.pathname == '/novo-usuario') && (+selectedProfileId == 3)) {
      const restrictions = restrictionsApp
        ?.filter(restriction => +restriction?.checked == 1)
        ?.map(restriction => restriction?.id);

      setSelectedAppRestrictionsId(selectedAppRestrictionsId?.concat(restrictions));
    }
  }, [restrictionsApp, selectedProfileId]);

  const selectRestriction = (restrictions, value) => {
    return restrictions?.includes(+value)
      ? restrictions.filter(id => +id != +value)
      : restrictions.concat(+value);
  }

  const profiles = prop('options', head(formatFilters(['profile'], selects)))
    ?.map(option => {
      return option?.value == ''
        ? {
          label: '--',
          value: ''
        } : {
          label: option?.name,
          value: option?.value
        }
    });

  const isCarWashWarningMessage =
    +formikEmployeeValues.profileId == 4 &&
    formattedAvailableEstablishments
      ?.filter(establishment => formikEmployeeValues?.establishments?.includes(establishment?.establishmentId))
      ?.some(establishment => establishment?.establishmentTypes?.includes(2) || establishment?.establishmentTypes?.includes("2"));

  const isCarWashWarningEstablishment = formattedAvailableEstablishments
    ?.filter(establishment => formikEmployeeValues?.establishments?.includes(establishment?.establishmentId))
    ?.filter(establishment => establishment?.establishmentTypes?.includes(2) || establishment?.establishmentTypes?.includes("2"))
    ?.map(establishment => establishment?.establishmentName)
    ?.join(', ');

  const restrictionsTypes = prop('options', head(formatFilters(['restrictionType'], selects)))
    .filter(restriction => restriction.label != '--');

  const handleInitialEstablishments = e =>
    availableEstablishments.length === 1 ? [availableEstablishments[0].establishmentId] : e;

  const initialState = compose(
    over(lensProp('establishments'), handleInitialEstablishments),
    assoc('employeeId', employeeId)
  )(submit);

  useEffect(() => {
    if(userRestrictions?.includes(RESTRICTION_PAGE.EDIT_USER)) {
      toast.error('Ação não autorizada.');
      history.push('/visao-geral');
    }

    if(userId && employeeId) {
      dispatch(loadEmployee(userId, employeeId));
    }

    if(history.location.pathname == '/novo-usuario') {
      dispatch(loadRestrictions(userId));
    }

    return () => dispatch(setEmptyEmployee());
  }, []);

  const getProfileTitle = profileId => {
    return profiles?.find(profile => profile.value == profileId)?.label;
  }

  const convertEstablishmentIdToUuid = establishments => {
    const establishmentsUuid = availableEstablishments
      ?.map(establishment => {
        if(establishments?.includes(establishment?.establishmentId)) {
          return establishment?.uuid;
        }
      })
      ?.filter(uuid => !!uuid);

    return establishmentsUuid;
  }

  const setRestrictions = (appRestrictions, userRestrictions, profileId) => {
    switch(profileId) {
      case 2:
        return [[], userRestrictions || []];
      case 3:
        return [appRestrictions || [], []];
      case 5:
        return [appRestrictions || [], []];
      default:
        return [[], []];
    }
  }

  const filterOptions = (options, state) => {
    const OPTIONS_LIMIT = 500;
    const defaultFilterOptions = createFilterOptions();

    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  }

  const getEmployeeSiteTooltip = id => {
    if(+id == 102) {
      return 'A Aba de negócio vai sumir do Menu do topo do site.';
    }

    if(+id == 103) {
      return 'A Aba de Clientes vai sumir do Menu do topo do site.';
    }

    if(+id == 104) {
      return 'Não poderá visualizar as Notas fiscais emitidas nem configurações.';
    }

    if(+id == 111) {
      return 'Não visualiza "engrenagem" onde tem todas as configurações.';
    }

    if(+id == 113) {
      return 'Não tem acesso aos boletos e notas fiscais referente aos pagamentos da Jump.';
    }

    if(+id == 114) {
      return 'Não visualiza acesso aos comissionamentos.';
    }

    if(+id == 115) {
      return 'Não visualiza acesso as folhas de pagamento.';
    }

    if(+id == 116) {
      return 'Não visualiza o acesso a Registro de sessões.';
    }

    if(+id == 117) {
      return 'Não visualiza a Aba de Financeiro.';
    }

    if(+id == 118) {
      return 'Não visualiza a aba de Clientes.';
    }

    if(+id == 105) {
      return 'Não permite qualquer edição do cliente.';
    }

    if(+id == 106) {
      return 'Não permite qualquer cadastro do cliente.';
    }

    if(+id == 18) {
      return 'Não permite cadastrar novo estabelecimento.';
    }

    if(+id == 24) {
      return 'Não permite cadastrar Usuário.';
    }

    if(+id == 19) {
      return 'Não permite editar os dados do estabelecimento como CNPJ, endereço etc.';
    }

    if(+id == 25) {
      return 'Não permite editar qualquer dado do usuário.';
    }

    if(+id == 23) {
      return 'Não permite acessar a lista de usuários.';
    }

    if(+id == 90) {
      return 'Não permite visualizar a página Estabelecimento.';
    }

    if(+id == 107) {
      return 'Não permite remover as Ordens de Serviço.';
    }

    if(+id == 108) {
      return 'Não permite nenhuma edição nas Ordens de serviço.';
    }

    if(+id == 109) {
      return 'Não permite remoção de nenhuma transação avulsa.';
    }

    if(+id == 110) {
      return 'Não permite editar o valor da sangria.';
    }

    if(+id == 112) {
      return 'Não permite remover os comprovantes anexados.';
    }

    return '';
  }

  const getEmployeeAppTooltip = id => {
    if(+id == 1) {
      return 'Não consegue acessar a tela com as informações do cliente.';
    }

    if(+id == 2) {
      return 'Não permite editar o valor da fatura do mensalista.';
    }

    if(+id == 3) {
      return 'Não permite editar os dados dos clientes.';
    }

    if(+id == 9) {
      return 'Não permite cadastrar cliente na tela onde visualiza a lista de clientes.';
    }

    if(+id == 10) {
      return 'Não permite receber pagamentos dos mensalistas e pós-pago.';
    }

    if(+id == 22) {
      return 'Não permite acessar a tela de configurações operacionais no aplicativo.';
    }

    if(+id == 5) {
      return 'Não permite alterar dados como placa, modelo e cor do veículo após dar entrada.';
    }

    if(+id == 6) {
      return 'Não permite alterar os valores dos serviços na tela de entrada.';
    }

    if(+id == 7) {
      return 'Não permite ativar pagamento antecipado na tela de configuração e na tela de entrada do veículo.';
    }

    if(+id == 11) {
      return 'Não permite corrigir ou alterar o meio de pagamento após receber o pagamento.';
    }

    if(+id == 17) {
      return 'Não permite alterar os valores do serviço na tela de saída do veículo.';
    }

    if(+id == 19) {
      return 'Não permite cancelar uma Ordem de serviço.';
    }

    if(+id == 20) {
      return 'Não permite alterar o valor do estacionamento na tela de pagamento.';
    }

    if(+id == 21) {
      return 'Não permite alterar a tabela de estacionamento na tela de saída. O usuário ainda pode ativar o convênio.';
    }

    if(+id == 23) {
      return 'Não permite dar desconto na Ordem de serviço.';
    }

    if(+id == 24) {
      return 'Não permite alterar horário de entrada do veículo.';
    }

    if(+id == 25) {
      return 'Não permite vincular ou desvincular a placa em um cliente mensalista.';
    }

    if(+id == 4) {
      return 'Não mostra as informações financeiras do caixa na tela principal do aplicativo.';
    }

    if(+id == 8) {
      return 'Não permite que o usuário desative a cobrança de estacionamento ao selecionar um serviço.';
    }

    if(+id == 12) {
      return 'Não permite visualizar os veículos pagos na listagem de veículo.';
    }

    if(+id == 13) {
      return 'Não mostra o saldo em dinheiro na tela de fechamento e não imprime o relatório do caixa após o fechamento.';
    }

    if(+id == 14) {
      return 'Não permite imprimir o relatório de veículo no pátio.';
    }

    if(+id == 15) {
      return 'Não permite imprimir o relatório de veículo fora do pátio.';
    }

    if(+id == 16) {
      return 'Não permite reimprimir o ticket de entrada do veículo.';
    }

    if(+id == 18) {
      return 'Não mostra os valores de cada serviço na impressão da ordem de serviço.';
    }

    return '';
  }

  const handleRegistrationByProfile = (values, actions) => {
    const params = omit(['employeeId'], values);
    const { profileId } = params;

    if(+profileId == 4 || +profileId == 6) {
      setFormikEmployeeValues(values);
      setFormikEmployeeActions(actions);
      return setIsProfileVerificationModalOpen(true);
    }

    return handleEmployeeRegistration(values, actions);
  }

  const handleEmployeeRegistration = (values, actions) => {
    const { resetForm } = actions;
    const params = omit(['employeeId'], values);
    const { profileId, email } = params;

    params.email = email.replace(/ /g, '');

    const [appRestrictions, userRestrictions] =
      setRestrictions(selectedAppRestrictionsId, selectedUserRestrictionsId, profileId);

    params.appRestrictions = appRestrictions.filter(restriction => parseInt(restriction));
    params.userRestrictions = userRestrictions.filter(restriction => parseInt(restriction));
    params.establishments = convertEstablishmentIdToUuid(params.establishments);

    if(!!params.resaleId) {
      params.resaleId = resaleId;
    }

    dispatch(upsertEmployee(userId, employeeId, params)).then(() => {
      resetForm();
      history.push('/usuarios');
    });
  }

  return(
    <Card title="Informações básicas">
      {isProfileVerificationModalOpen && (
        <Modal
          id="profileVerificationModal"
          title="Atenção"
          scroll="body"
          maxWidth="md"
          open={isProfileVerificationModalOpen}
          onClose={() => setIsProfileVerificationModalOpen(false)}
          modalActions={
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  color="error"
                  onClick={() => setIsProfileVerificationModalOpen(false)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => handleEmployeeRegistration(formikEmployeeValues, formikEmployeeActions)}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Typography>
            Este usuário não pode fazer recebimentos pelo aplicativo operacional.
            Cadastre com o perfil Operador ou Supervisor caso deseje que este usuário possa fazer essa função.
          </Typography>
          {isCarWashWarningMessage && (
            <>
              <br /><br />
              O estabelecimento <b>{isCarWashWarningEstablishment}</b> é uma estética automotiva, têm certeza que deseja salvar como Manobrista?
            </>
          )}
        </Modal>
      )}
      <Formik
        enableReinitialize
        initialValues={{ ...initialState, mainUser, resaleId }}
        validationSchema={validations}
        onSubmit={handleRegistrationByProfile}
      >
        {({ ...formikProps }) => {
          const {
            errors,
            values: { phone }
          } = formikProps;

          const isPhoneError =
            !errors.phone &&
            !formikProps.errors.phone &&
            (phone?.includes('(55)') || phone?.includes('(55) 1'));

          return(
            <Grid item xs={12}>
              {(+mainUser == 1 && isUserAdmin) && (
                <Grid className={classes.infoCard}>
                  <Typography className={classes.infoCardText}>
                    Este é um usuário principal.
                  </Typography>
                </Grid>
              )}
              <Form>
                <Loader isLoading={isLoading}>
                  <Grid container>
                    <Grid item md={6} xs={12} className={classes.firstGrid}>
                      <Input
                        autoFocus
                        name="name"
                        label="Nome"
                        value={formikProps.values.name}
                        error={formikProps.errors.name}
                        helperText={formikProps.errors.name}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.firstGrid}>
                      <Status
                        value={formikProps.values.status}
                        onChange={formikProps.handleChange}
                        disabled={mainUser == 1}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={6} xs={12} className={classes.grid}>
                      <Input
                        name="email"
                        label="Email"
                        placeholder="usuario@exemplo.com"
                        value={formikProps.values.email}
                        error={formikProps.errors.email}
                        helperText={formikProps.errors.email}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.grid}>
                      <Input
                        name="phone"
                        type="phone"
                        label="Telefone"
                        placeholder="(00) 0000-0000"
                        value={formikProps.values.phone}
                        error={formikProps.errors.phone}
                        helperText={formikProps.errors.phone}
                        onChange={formikProps.handleChange}
                      />
                      {isPhoneError && (
                        <div style={{ margin: '0 14px 0 14px', color: '#ebc707' }}>
                          <small>Por favor, escreva seu telefone no formato DDD + número de celular</small>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={6} xs={12} className={classes.grid}>
                      <Select
                        name="profileId"
                        label="Perfil"
                        options={profiles}
                        value={parseInt(formikProps.values.profileId, 10)}
                        error={formikProps.errors.profileId}
                        helperText={formikProps.errors.profileId}
                        onChange={e => {
                          if(isEmployeeRegister) {
                            setSelectedUserRestrictionsId([]);
                            setSelectedAppRestrictionsId([]);
                          }

                          setSelectedProfileId(e.target.value);
                          return formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.grid}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="establishments"
                        options={formattedAvailableEstablishments}
                        filterOptions={filterOptions}
                        value={formattedAvailableEstablishments?.filter(establishment => formikProps.values.establishments?.includes(establishment.establishmentId)) || []}
                        onChange={(_, establishments) => {
                          return formikProps.setFieldValue('establishments', establishments?.map(establishment => establishment?.establishmentId));
                        }}
                        getOptionLabel={option => option.label}
                        getOptionSelected={(option, value) => option.establishmentId == value.establishmentId}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            color="primary"
                            label="Vincular a estabelecimentos"
                            error={formikProps.errors.establishments}
                            helperText={formikProps.errors.establishments}
                          />
                        )}
                        renderOption={(option, { selected }) => (
                          <li color="primary" className={classes.establishmentsRenderOption}>
                            <Checkbox checked={selected} />
                            <Typography color="primary">{option.label}</Typography>
                          </li>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <EmployeesDescription profileId={formikProps.values.profileId} />
                  </Grid>
                  {!(+employeeId == +userId) && (
                    <Loader isLoading={isRestrictionsLoading}>
                      <Grid container>
                        {!!formikProps.values.profileId &&
                        profilesToDisplay?.includes(+formikProps.values.profileId) ? (
                          <>
                            {(+formikProps.values.profileId == 2) ? (
                              <>
                                <Grid item xs={12} className={classes.grid}>
                                  <Typography variant="h6" color="primary">
                                    Restrições personalizadas do usuário {getProfileTitle(formikProps.values.profileId)}
                                  </Typography>
                                </Grid>
                                <Grid container>
                                  {restrictionsTypes
                                  ?.filter(restriction => siteRestrictionsId?.includes(+restriction?.id))
                                  ?.map(restriction => (
                                    <Grid item xs={12} className={classes.grid} style={{ marginTop: 15 }}>
                                      <Typography variant="h6" color="textSecondary">
                                        {restriction?.label}
                                      </Typography>
                                      <Grid container>
                                        {restrictionsUser
                                        ?.filter(userRestriction => +userRestriction?.type == +restriction?.id)
                                        ?.map(userRestriction => (
                                          <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  name="userRestrictions"
                                                  color="primary"
                                                  id={`userRestrictions.${userRestriction?.restrictionId}`}
                                                  checked={selectedUserRestrictionsId?.includes(+userRestriction?.restrictionId)}
                                                  value={userRestriction?.restrictionId}
                                                  onChange={event => setSelectedUserRestrictionsId(selectRestriction(selectedUserRestrictionsId, event.target.value))}
                                                />
                                              }
                                              label={
                                                <>
                                                  {getEmployeeSiteTooltip(userRestriction?.restrictionId) ? (
                                                    <Tooltip
                                                      placement="top"
                                                      title={
                                                        <Typography>{getEmployeeSiteTooltip(userRestriction?.restrictionId)}</Typography>
                                                      }
                                                    >
                                                      <Typography color="primary">
                                                        {userRestriction?.name}
                                                      </Typography>
                                                    </Tooltip>
                                                  ) : (
                                                    <Typography color="primary">
                                                      {userRestriction?.name}
                                                    </Typography>
                                                  )}
                                                </>
                                              }
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={12} className={classes.grid}>
                                  <Typography variant="h6" color="primary">
                                    Restrições personalizadas do usuário {getProfileTitle(formikProps.values.profileId)}
                                  </Typography>
                                </Grid>
                                <Grid container>
                                  {restrictionsTypes
                                  ?.filter(restriction => appRestrictionsId?.includes(restriction?.id))
                                  ?.map(restriction => (
                                    <Grid item xs={12} className={classes.grid} style={{ marginTop: 15 }}>
                                      <Typography variant="h6" color="textSecondary">
                                        {restriction?.label}
                                      </Typography>
                                      <Grid container>
                                        {restrictionsApp
                                        ?.filter(appRestriction => +appRestriction?.type == restriction?.id)
                                        ?.map(appRestriction => (
                                          <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  name="appRestrictions"
                                                  color="primary"
                                                  id={`appRestriction.${appRestriction?.id}`}
                                                  checked={selectedAppRestrictionsId?.includes(+appRestriction?.id)}
                                                  value={appRestriction?.id}
                                                  onChange={event => setSelectedAppRestrictionsId(selectRestriction(selectedAppRestrictionsId, event.target.value))}
                                                />
                                              }
                                              label={
                                                <>
                                                  {getEmployeeAppTooltip(appRestriction?.id) ? (
                                                    <Tooltip
                                                      placement="top"
                                                      title={
                                                        <Typography>{getEmployeeAppTooltip(appRestriction?.id)}</Typography>
                                                      }
                                                    >
                                                      <Typography color="primary">
                                                        {appRestriction?.description}
                                                      </Typography>
                                                    </Tooltip>
                                                  ) : (
                                                    <Typography color="primary">
                                                      {appRestriction?.description}
                                                    </Typography>
                                                  )}
                                                </>
                                              }
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </>
                            )}
                          </>
                        ) : null}
                      </Grid>
                    </Loader>
                  )}
                  <Grid item xs={12} className={classes.grid} style={{ marginTop: 15 }}>
                    <Typography variant="h6" color="primary">
                      Alteração de senha
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid item md={6} xs={12} className={classes.grid}>
                      <Input
                        name="password"
                        type="password"
                        label="Senha"
                        placeholder="Senha"
                        value={formikProps.values.password}
                        error={formikProps.errors.password}
                        helperText={formikProps.errors.password}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.grid}>
                      <Input
                        name="passwordConfirm"
                        type="password"
                        label="Confirmar senha"
                        placeholder="Confirmar senha"
                        value={formikProps.values.passwordConfirm}
                        error={formikProps.errors.passwordConfirm}
                        helperText={formikProps.errors.passwordConfirm}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CardFooter>
                      <Button
                        type="submit"
                        color="success"
                        loading={isLoading}
                      >
                        Salvar
                      </Button>
                    </CardFooter>
                  </Grid>
                </Loader>
              </Form>
            </Grid>
          );
        }}
      </Formik>
    </Card>
  );
}